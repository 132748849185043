
import {
  defineComponent,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  ref,
} from "vue";
import { useStore } from "vuex";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import router from "./router";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "app",
  components: {},
  setup() {
    let userActivityThrottlerTimeout = 0;
    let sessionTimeoutMinutes = 1000 * 60 * 30; //Session Timeout for 10 minutes (ms * seconds * minutes)
    let userActivityTimeout = 0;
    let isInactive = false;
    const router = useRouter();

    const store = useStore();
    onMounted(() => {
      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
       */
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);
      sessionStorage.setItem(
        "DomainName",
        process.env.VUE_APP_DomainName.toUpperCase()
      );
    });

    onBeforeMount(() => {
      activateActivityTracker();
    });

    onBeforeUnmount(() => {
      window.removeEventListener("mousemove", userActivityThrottler);
      window.removeEventListener("scroll", userActivityThrottler);
      window.removeEventListener("keydown", userActivityThrottler);
      window.removeEventListener("resize", userActivityThrottler);
      clearTimeout(userActivityTimeout);
      clearTimeout(userActivityThrottlerTimeout);
    });

    function resetUserActivityTimeout() {
      clearTimeout(userActivityTimeout);
      userActivityTimeout = setTimeout(() => {
        inactiveUserAction();
      }, sessionTimeoutMinutes);
    }

    function inactiveUserAction() {
      store
        .dispatch(Actions.LOGOUT)
        .then(() => router.push({ name: "sign-in" }));
      sessionStorage.clear();
    }

    function activateActivityTracker() {
      window.addEventListener("mousemove", resetUserActivityTimeout);
      window.addEventListener("scroll", resetUserActivityTimeout);
      window.addEventListener("keydown", resetUserActivityTimeout);
      window.addEventListener("resize", resetUserActivityTimeout);
    }

    function userActivityThrottler() {
      if (!userActivityThrottlerTimeout) {
        userActivityThrottlerTimeout = setTimeout(() => {
          resetUserActivityTimeout();

          clearTimeout(userActivityThrottlerTimeout);
          userActivityThrottlerTimeout = 0;
        }, sessionTimeoutMinutes);
      }
    }

    //set timeout at the built in- created even inside setup()
    //  let self = this;
    // setTimeout(function()
    // {
    //   alert("session timeout");
    // //self.$sesssion.destroy();
    // },500);

    return {
      userActivityThrottler,
      activateActivityTracker,
      resetUserActivityTimeout,
      inactiveUserAction,
      userActivityTimeout,
      userActivityThrottlerTimeout,
      isInactive,
    };
  },
});
